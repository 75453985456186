import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'theme-ui'

const styles = {
  wrapper: (src = '', isLargeScreen) => ({
    bg: `contentBg`,
    backgroundImage: src && [`none`, `none`, `url(${src})`],
    backgroundRepeat: `no-repeat`,
    borderRadius: `default`,
    margin: 'auto',
    marginTop: '2rem',
    width: isLargeScreen ? '100%' : '100%' // Adjusted width based on screen size
  }),

  adsArticle: (src = '') => ({
    margin: `auto`
  })
}

const AdsArticle = ({ head }) => {
  const adRef = useRef()
  const [isLargeScreen, setIsLargeScreen] = useState(false)
  const [isViewportNarrow, setIsViewportNarrow] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsLargeScreen(window.innerWidth >= 1440)
      setIsViewportNarrow(window.innerWidth < 768)
    }

    const script = document.createElement('script')
    script.src =
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
    script.async = true
    document.head.appendChild(script)

    script.onload = () => {
      if (adRef.current && !adRef.current.innerHTML) {
        ;(adsbygoogle = window.adsbygoogle || []).push({})
      }
    }

    function handleResize() {
      if (typeof window !== 'undefined') {
        setIsLargeScreen(window.innerWidth >= 1440)
        setIsViewportNarrow(window.innerWidth < 768)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      document.head.removeChild(script)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isViewportNarrow ? null : (
    <div className='adsArticle' sx={styles.adsArticle()}>
      <Box sx={styles.wrapper('', isLargeScreen)}>
        <ins
          ref={adRef}
          className='adsbygoogle'
          style={{
            display: 'block',
            maxWidth: '100%',
            width: '500px',
            height: '200px'
          }}
          data-ad-client={process.env.GATSBY_GOOGLE_ADSENSE_PUBLISHER_ID}
          data-ad-format='auto'
          data-full-width-responsive='true'
        ></ins>
      </Box>
    </div>
  )
}

AdsArticle.propTypes = {
  head: PropTypes.string
}

export default AdsArticle
